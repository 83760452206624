import { Heading, Text } from "@chakra-ui/react";
import SectionContainer from "../../components/SectionContainer/SectionContainer";

const About = () => {
  return (
    <SectionContainer title="Sobre" flexDirection="column">
      <Text>
        A iniciativa de E-Sports dentro do Coritiba Football Club surgiu a
        partir do desejo de contar com uma equipe com nível internacional,{" "}
        <strong>
          que represente os valores do clube com grandiosidade e talento, também
          no universo dos games.
        </strong>
      </Text>
      <br />
      <Text>
        Somos uma equipe multigaming, contamos com uma staff experiente dentro
        do cenário de E-Sports no Brasil. Além disso, temos alguns dos melhores
        jogadores de suas modalidades em nosso time.
      </Text>

      <br />

      <Heading size="md">DEDICAÇÃO É O NOSSO LEMA.</Heading>
    </SectionContainer>
  );
};

export default About;
