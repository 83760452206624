import { Button, Flex, Grid, Image, Link } from "@chakra-ui/react";
import SectionContainer from "../../components/SectionContainer/SectionContainer";

export const sponsors = [
  {
    name: "Coritiba",
    imagePath: "assets/images/sponsors/logo-coxa-futebol.png",
    url: "https://coritiba.com.br/Portal",
  },
  {
    name: "PlayOff",
    imagePath: "assets/images/sponsors/logo-playoff.png",
    url: "https://www.playoffmarketing.com.br/",
  },
  {
    name: "WayUp",
    imagePath: "assets/images/sponsors/logo-wayup.png",
    url: "https://www.wayup.gg/",
  },
  {
    name: "Player Games",
    imagePath: "assets/images/sponsors/logo-player-games.png",
    url: "https://www.playergames.com.br/",
  },
];

const Sponsors = () => {
  return (
    <SectionContainer title="Parceiros / Apoiadores">
      <Grid
        width="100%"
        templateColumns={{
          base: "repeat(auto-fill,minmax(20em, 1fr))",
          sm: "repeat(auto-fill,minmax(20em, 1fr))",
          md: "repeat(auto-fill,minmax(20em, 1fr))",
          lg: "repeat(auto-fill,minmax(20em, 1fr))",
          xl: "repeat(auto-fill,minmax(25em, 1fr))",
          "2xl": "repeat(auto-fill,minmax(30em, 1fr))",
        }}
      >
        {sponsors.map((sponsor) => (
          <Flex
            flexDirection="column"
            key={sponsor.name}
            padding="1em"
            align="center"
            justify="space-between"
          >
            <Flex justify="center" width="100%">
              <Image
                width="fit-content"
                maxH="6em"
                src={`${process.env.PUBLIC_URL}${sponsor.imagePath}`}
                alt="Patrocinador"
                objectFit="contain"
              />
            </Flex>

            <Button
              as={Link}
              margin="1.5em 0"
              width={{ base: "100%", sm: "fit-content", md: "fit-content" }}
              href={sponsor.url}
              isExternal
              _hover={{
                textDecoration: "none",
              }}
            >
              Visitar o site
            </Button>
          </Flex>
        ))}
      </Grid>
    </SectionContainer>
  );
};

export default Sponsors;
