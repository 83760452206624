import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { sponsors } from "../Sponsors/Sponsors";

const gamesLogos = [
  {
    path: "dota-logo.png",
  },
  {
    path: "apex-logo.png",
  },
  {
    path: "brawl-star-logo.png",
  },
  {
    path: "free-fire-logo.png",
  },
];

const Home = () => {
  return (
    <Flex flexDirection="column">
      <Box
        h="70vh"
        backgroundImage={
          process.env.PUBLIC_URL + "assets/images/banner-home.png"
        }
        backgroundSize="cover"
        backgroundPosition="center"
      />
      <Flex as="section" align="center" flexDirection="column">
        <Heading
          size={{ base: "md", sm: "lg", md: "lg", lg: "lg" }}
          color="neutral.black"
        >
          O que é o Coritiba E-Sports?
        </Heading>

        <Box width={{ base: "100%", sm: "100%", md: "80%" }}>
          <Text margin="2em 0" textAlign="center" color="grey.700">
            A iniciativa de E-Sports dentro do Coritiba Football Club surgiu a
            partir do desejo de contar com uma equipe com nível internacional,
            que represente os valores do clube com grandiosidade e talento,
            também no universo dos games.
          </Text>
        </Box>

        <Button
          as={RouterLink}
          to="/sobre"
          _hover={{
            textDecoration: "none",
          }}
          width={{ base: "100%", sm: "100%", md: "unset" }}
        >
          Ver mais
        </Button>
      </Flex>
      <Flex position="relative" h="15vh">
        <Image
          src={`${process.env.PUBLIC_URL}assets/images/games-background.png`}
          height="auto"
          width="100%"
          objectFit="cover"
        />
        <Flex
          as={Box}
          position="absolute"
          left="50%"
          top="50%"
          transform="translate(-50%, -50%)"
          flexDirection="row"
          w={{ base: "100%", sm: "70%", md: "45%" }}
          justify="space-evenly"
        >
          {gamesLogos.map((gameLogo) => (
            <Image
              key={gameLogo.path}
              src={`${process.env.PUBLIC_URL}assets/images/${gameLogo.path}`}
              boxSize={{ base: "2em", sm: "2.5em", md: "2.5em" }}
            />
          ))}
        </Flex>
      </Flex>
      <Flex
        backgroundImage={
          process.env.PUBLIC_URL + "assets/images/store-background.png"
        }
        backgroundSize="cover"
        backgroundPosition="65%"
      >
        <Flex
          as="section"
          bg="transparent"
          alignItems="center"
          justifyContent="center"
        >
          <Image
            src={`${process.env.PUBLIC_URL}assets/images/camisa-coxa.png`}
            height="100%"
            maxH="15em"
            objectFit="cover"
            padding={{
              base: "0 1em 0 0",
              sm: "0 2.5em 0 1.25em",
              md: "0 4.25em 0 4.25em",
              lg: "0 6.25em 0 0",
            }}
          />
          <Flex flexDirection="column" alignItems="flex-start">
            <Heading size="lg" color="neutral.white">
              O manto do E-Sports foi escolhido
            </Heading>
            <Text color="grey.100" margin="0.5em 0 1.5em 0">
              Essa e outras novidades na Wayup.gg
            </Text>
            <Button
              as={Link}
              href="https://www.wayup.gg/coritibaesports"
              isExternal
              _hover={{
                textDecoration: "none",
              }}
            >
              Visitar loja
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        as="section"
        bg="neutral.white"
        flexWrap="wrap"
        justify={{ base: "center", sm: "center", md: "space-evenly" }}
      >
        {sponsors.map((sponsor) => (
          <Image
            key={sponsor.imagePath}
            src={`${process.env.PUBLIC_URL}${sponsor.imagePath}`}
            // boxSize={{ base: "2em", sm: "2.5em", md: "2.5em" }}
            maxH="6em"
            margin="1em"
            width="fit-content"
            objectFit="contain"
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default Home;
