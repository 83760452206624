import { Flex, Grid, Heading, Image, Text } from "@chakra-ui/react";
import SectionContainer from "../../components/SectionContainer/SectionContainer";

const games = [
  {
    name: "Dota 2",
    imagePath: "assets/images/games/logo-dota.png",
  },
  {
    name: "League of Legends",
    imagePath: "assets/images/games/logo-lol.png",
  },
  {
    name: "Free Fire",
    imagePath: "assets/images/games/logo-ff.png",
  },
  {
    name: "Counter Strike: Global Offensive",
    imagePath: "assets/images/games/logo-cs.png",
  },
  {
    name: "Rocket League",
    imagePath: "assets/images/games/logo-rocket.png",
  },
  {
    name: "Apex Legends",
    imagePath: "assets/images/games/logo-apex.png",
  },
  {
    name: "Call of Duty: Warzone",
    imagePath: "assets/images/games/logo-cod.png",
  },
  {
    name: "Fifa",
    imagePath: "assets/images/games/logo-fifa.png",
  },
  {
    name: "World of Warcraft",
    imagePath: "assets/images/games/logo-wow.png",
  },
  {
    name: "Valorant",
    imagePath: "assets/images/games/logo-valorant.png",
  },
  {
    name: "Fortnite",
    imagePath: "assets/images/games/logo-fortnite.png",
  },
  {
    name: "Grand Chase",
    imagePath: "assets/images/games/logo-gc.png",
  },
];

const ESports = () => {
  return (
    <SectionContainer title="O que é E-Sports?" flexDirection="column">
      <Text>
        Os esportes eletrônicos já fazem parte da sociedade. Antigamente, o
        programa de domingo mais importante para um jovem adulto era acompanhar,
        seja pela TV ou no campo, o jogo do seu time de coração.
      </Text>

      <br />

      <Heading size="md">
        Atualmente, os E-Sports dividem a atenção desse público.
      </Heading>

      <br />

      <Text>
        Diariamente, milhares de fás assistem, torcem e jogam competições de
        esportes eletrônicos em todo o mundo. Gerando engajamento igual ou maior
        do que uma partida de futebol "tradicional". No E-Sports, profissionais
        disputam campeonatos de jogos eletrônicos de diversas modalidades, como
        estratégia, luta, tiro em primeira pessoa (FPS). Multiplayers Online
        Battle Arena (MOBA), entre outros.
      </Text>

      <Grid
        margin="2.5em 0 0 0"
        width="100%"
        templateColumns={{
          base: "repeat(auto-fill,minmax(8em, 1fr))",
          sm: "repeat(auto-fill,minmax(10em, 1fr))",
          md: "repeat(auto-fill,minmax(10em, 1fr))",
          lg: "repeat(auto-fill,minmax(10em, 1fr))",
          xl: "repeat(auto-fill,minmax(15em, 1fr))",
          "2xl": "repeat(auto-fill,minmax(15em, 1fr))",
        }}
        gap="1.5em"
      >
        {games.map((game) => (
          <Flex
            flexDirection="column"
            key={game.name}
            padding="1em"
            align="center"
            justify="center"
            borderRadius="0.5em"
            border="1px solid"
            borderColor="gray.100"
          >
            <Image
              src={`${process.env.PUBLIC_URL}${game.imagePath}`}
              alt={`Logo ${game.name}`}
              objectFit="contain"
              maxH={{ base: "5em", sm: "5em", md: "5em", lg: "5em", xl: "7em" }}
            />
          </Flex>
        ))}
      </Grid>
    </SectionContainer>
  );
};

export default ESports;
