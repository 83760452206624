import { Navigate, Route, Routes } from "react-router-dom";
import App from "./App";
import About from "./pages/About/About";
import ContactUs from "./pages/ContactUs/ContactUs";
import ESports from "./pages/eSports/ESports";
import Home from "./pages/Home/Home";
import News from "./pages/News/News";
import Sponsors from "./pages/Sponsors/Sponsors";
// import Teams from "./pages/Teams/Teams";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<App />}>
        <Route path="/" element={<Home />} index />
        <Route path="sobre" element={<About />} />
        {/* <Route path="team" element={<Teams />} /> */}
        <Route path="noticias" element={<News />} />
        <Route path="patrocinadores-parceiros" element={<Sponsors />} />
        <Route path="e-sports" element={<ESports />} />
        <Route path="contato" element={<ContactUs />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};
