import { Button, Heading, Image, Link, Text } from "@chakra-ui/react";
import SectionContainer from "../../components/SectionContainer/SectionContainer";

const News = () => {
  return (
    <SectionContainer
      title="Notícias"
      flexDirection="column"
      maxWidth={{ base: "100%", md: "50%" }}
    >
      <Image
        src={`${process.env.PUBLIC_URL}assets/images/news-banner.png`}
        height="auto"
        width="100%"
        objectFit="cover"
      />

      <br />

      <Heading size="md" color="neutral.black">
        Coritiba E-Sports: O Coritiba está entrando no mundo dos esportes
        eletrônicos com a Real Deal
      </Heading>

      <br />

      <Text>
        Uma parceria entre o Coritiba Foot Ball Club e a Real Deal, empresa
        presente desde 2017 nos esportes eletrônicos e que possui um staff
        experiente e comprometido, promete a fazer a diferença em colocar o nome
        do Coxa no E-sports.
      </Text>

      <br />

      <Button
        as={Link}
        href="https://coritiba.com.br/artigo/38709/coritiba_e_sports"
        isExternal
        width={{ base: "100%", sm: "fit-content", md: "fit-content" }}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text>Continuar lendo</Text>
      </Button>
    </SectionContainer>
  );
};

export default News;
